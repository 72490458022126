import cn from 'classnames'
import React, { useState, useRef } from 'react'
import Dropdown from '../../components/Dropdown'
import TooltipGlodal from '../../components/TooltipGlodal'
import ExperimentalFeatures from './ExperimentalFeatures'
import Login from './Login'
import Payment from './Payment'
import PlatformSettings from './PlatformSettings'
import ProfileInformation from './ProfileInformation'
import styles from './Settings.module.sass'

const Settings = () => {
    const navigation = [
        {
            title: 'Profile & Special Perks',
            action: () =>
                scrollToProfile.current.scrollIntoView({ behavior: 'smooth' }),
        },
        {
            title: 'Platform Settings',
            action: () =>
                scrollToPlatformSettings.current.scrollIntoView({
                    behavior: 'smooth',
                }),
        },
        {
            title: 'Account',
            action: () =>
                scrollToLogin.current.scrollIntoView({ behavior: 'smooth' }),
        },
        /*
        {
            title: 'Payment',
            action: () =>
                scrollToPayment.current.scrollIntoView({ behavior: 'smooth' }),
        },
        */
        {
            title: 'Experimental Features',
            action: () =>
                scrollToExperimentalFeatures.current.scrollIntoView({
                    behavior: 'smooth',
                }),
        },
    ]
    const options = []
    navigation.map((x) => options.push(x.title))
    const [activeTab, setActiveTab] = useState(options[0])

    const [activeIndex, setActiveIndex] = useState(0)
    const scrollToProfile = useRef(null)
    const scrollToLogin = useRef(null)
    const scrollToExperimentalFeatures = useRef(null)
    const scrollToPlatformSettings = useRef(null)

    const handleClick = (x, index) => {
        setActiveIndex(index)
        x.action()
    }

    return (
        <>
            <div className={styles.settings}>
                <div className={styles.menu}>
                    {navigation.map((x, index) => (
                        <button
                            className={cn(styles.button, {
                                [styles.active]: activeIndex === index,
                            })}
                            key={index}
                            onClick={() => handleClick(x, index)}
                        >
                            {x.title}
                        </button>
                    ))}
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.list}>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: activeTab === options[0],
                            })}
                        >
                            <div
                                className={styles.anchor}
                                ref={scrollToProfile}
                            />
                            <ProfileInformation />
                        </div>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: activeTab === options[0],
                            })}
                        >
                            <div
                                className={styles.anchor}
                                ref={scrollToPlatformSettings}
                            />
                            <PlatformSettings />
                        </div>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: activeTab === options[2],
                            })}
                        >
                            <div
                                className={styles.anchor}
                                ref={scrollToExperimentalFeatures}
                            />
                            <ExperimentalFeatures />
                        </div>
                    </div>
                </div>
            </div>
            <TooltipGlodal />
        </>
    )
}

export default Settings
