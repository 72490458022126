import cn from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetUserSubscriptionQuery } from 'store/api/usersApi'
import styled, { css } from 'styled-components'
import Icon from '../Icon'
import styles from './Header.module.sass'

import User from './User'

const Header = ({ onOpen }) => {
    const [visible, setVisible] = useState(false)
    const { data: meData } = useGetUserSubscriptionQuery()
    const isVip = meData?.badges?.includes('earlyAccess')

    const handleClick = () => {
        onOpen()
        setVisible(false)
    }

    const { isSidebarExpanded } = useSelector((state) => state.display)

    return (
        <header
            className={cn(styles.header, {
                [styles.toggleSidebarDesktop]: !isSidebarExpanded,
            })}
        >
            <button className={styles.burger} onClick={() => handleClick()} />
            {/*<Search className={cn(styles.search, { [styles.visible]: visible })} />*/}
            <button
                className={cn(styles.buttonSearch, {
                    [styles.active]: visible,
                })}
                onClick={() => setVisible(!visible)}
            >
                <Icon name='search' size='24' />
            </button>

            <div className={styles.control} onClick={() => setVisible(false)}>
                {/* <Messages className={styles.messages} />
        <Notification className={styles.notification} /> */}
                {isVip && (
                    <div>
                        <Link to='/settings'>
                            <h4
                                style={{
                                    background:
                                        'linear-gradient(to bottom, #6A52FF, #AA69FF, #EEEEEE )',
                                    padding: '0.5px 4px',
                                    color: '#0C0C0C',
                                    fontSize: '12px',
                                    letterSpacing: '0.02em',
                                    fontWeight: 'medium',
                                    borderRadius: '4px',
                                }}
                            >
                                VIP
                            </h4>
                        </Link>
                    </div>
                )}
                <User className={styles.user} />
            </div>
            {/*
            <div className={styles.btns}>
                <Link className={styles.link} to="/sign-in">
                Sign in
                </Link>
                <Link className={cn("button", styles.button)} to="/sign-up">
                Sign up
                </Link>
            </div>
      */}
        </header>
    )
}

export default Header
