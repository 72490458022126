import { createSelector } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import Button from 'components/Button'
import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { uppy } from 'store/uppy'

const selectUppy = createSelector(
    (state) => state,
    (state) => state.uppy,
)
const selectUppyFiles = createSelector(
    (state) => state,
    (state) => state.uppy.renderUpload.files,
)

const Up = () => {
    const uppyState = useSelector(selectUppy)
    const [uppyId, setUppyId] = useState('')

    const uppyFilesState = useSelector(selectUppyFiles)

    const handleUpload = async () => {
        console.log('calling upload')
        const result = await uppy.upload()
        if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
                console.error(file.error)
            })
        }
    }
    const onCancel = () => {
        console.log('uppy cancel')
        uppy.cancelAll()
    }
    const onDrop = useCallback((acceptedFiles) => {
        const id = uppy.addFile(acceptedFiles[0])
        setUppyId(id)
        // Do something with the files
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })
    return (
        <>
            <div
                {...getRootProps()}
                style={{
                    backgroundColor: 'blue',
                    border: '2px dashed',
                    borderRadius: '4px',
                    padding: '20px',
                    width: '500px',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '50px',
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop Uppy File here</p>
                ) : (
                    <p>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                )}
            </div>
            <h1>progress: {uppyFilesState[uppyId]?.progress?.percentage}</h1>
            <div style={{ width: '500px' }}>
                <sub>returned uppy id : {JSON.stringify(uppyId)}</sub>
                <p>{JSON.stringify(uppyFilesState, null, 2)}</p>
            </div>
            <Button type='button' onClick={handleUpload}>
                Upload
            </Button>
            <Button
                type='button'
                onClick={() => {
                    console.log('click registered')
                    onCancel()
                }}
            >
                Cancel
            </Button>
        </>
    )
}

export default Up
