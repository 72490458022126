import { createSlice } from '@reduxjs/toolkit'

import { items } from '../../mocks/faq'

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        faq: items,
    },
})

export const selectFaq = (state) => state.faq.faq

export default faqSlice.reducer
