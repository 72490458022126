export const defaultTheme = {
    colors: {
        WHITE: '#FFFFFF',
        BLACK: '#000000',
        SATIN_BLACK: '#1A1D1F',
        ALMOST_WHITE: '#FCFCFC',
        WHITE_GRAY: '#F4F4F4',
        LIGHT_GRAY: '#EFEFEF',
        SILVER: '#CDCDCD',
        DARK_GRAY: '#33383F',
        SONIC_SILVER: '#9A9FA5',
        DARK_SLATE_GRAY: '#31353B',
        SLATE_GRAY: '#6F767E',
        BLUE_GRAY: '#272B30',
        SMOKY_GRAY: '#9A9FA5',
        GRAYISH: '#A7A7A7',
        ELECTRIC_BLUE: '#2A85FF',
        VIBRANT_BLUE: '#0069f6',
        LAVENDER_BLUE: '#8E59FF',
        TEXT_MUTED: '#718096',
        LIGHT_BLUE: '#b1e5fc',
        CLOSE_BLACK: '#111315',
        APRICOT: '#FFD88D',
        SALMON_PINK: '#FF6A55',
        ELECTRIC_PINK: '#FD8EFF',
        PASTEL_PINK: '#FFD9F4',
        PURPLE_LAVENDER: '#AE85EA',
        BLUE_VIOLET: '#7B61FF',
        GREEN: '#83BF6E',
        LIGHT_BLUE_GREEN: '#80D0C7',
        LIME_GREEN: '#34EF66',
        PERIWINKLE: '#CABDFF',
        LIGHT_YELLOW: '#F6E06E',
        NIGHT_FADE: '#A18CD1',
        SEMI_TRANSPARENT_LIGHT_YELLOW: 'rgba(246,224,110,0.15)',
        SEMI_TRANSPARENT_GRAY: 'rgba(234, 250, 228, 0.15)',
        SEMI_TRANSPARENT_SALMON_PINK: 'rgba(255, 106, 85, 0.15)',
        LIGHT_BLACK: 'rgba(0, 0, 0, 0.04)',
        SEMI_TRANSPARENT_BLACK: 'rgba(0, 0, 0, 0.25)',
        TOMATO_RED: 'linear-gradient(-45deg, #ff6a55 0%, #ff6a55 100%)',
        GRADIENT_BLUE_SKY: 'linear-gradient(-45deg, #210cae 0%, #4dc9e6 100%)',
        PURPLE_STUDIO: 'linear-gradient(to bottom, #6A52FF, #AA69FF, #FFFFFF )',
        GRADIENT_RANGE_SLIDER:
            'linear-gradient(to right, #FFFFFF,#A3CAFF,#0353BF, #B095FF, #8E59FF)',
    },
    breakpoints: {
        XS: '474px',
        SM: '639px',
        MD: '767px',
        LG_MIN: '1023px',
        LG_MAX: '1259px',
        XL: '1339px',
        XXL: '1419px',
    },
    fontweight: {
        THIN: '100',
        EXTRA_LIGHT: '200',
        LIGHT: '300',
        NORMAL: '400',
        MEDIUM: '500',
        SEMI_BOLD: '600',
        BOLD: '700',
        EXTRA_BOLD: '800',
        HEAVY: '900',
    },
}
