import { useEffect } from 'react'
import { useLocation } from 'react-router'
import analytics from 'utils/analytics'

const useSegmentOnLocationChange = (handleLocationChange) => {
    const location = useLocation()
    useEffect(() => {
        analytics.page(location.pathname)
    }, [location])
}

export default useSegmentOnLocationChange
