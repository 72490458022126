import cn from 'classnames'
import React from 'react'
import styles from './Switch.module.sass'

const Switch = ({ className, value, onChange }) => {
    return (
        <label className={cn(styles.switch, className)}>
            <input
                className={styles.input}
                type='checkbox'
                checked={value}
                onChange={onChange}
            />
            <span className={styles.inner}>
                <span className={styles.box} />
            </span>
        </label>
    )
}

export default Switch
