import * as Sentry from '@sentry/react'
import { downZip } from 'downzipObj'
import LogRocket from 'logrocket'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import App from './App'
import { defaultTheme } from './constants/THEME'
import { persistor, store } from './store/index'

if (process.env.REACT_APP_NODE_ENV !== 'production') {
    console.group('Env Vars:')
    Object.keys(process.env).forEach((key) => {
        if (key.startsWith('REACT_APP')) {
            console.log(`${key}: ${process.env[key]}`)
        }
    })
    console.groupEnd()
}

if (process.env.REACT_APP_SENTRY_NODE_ENV) {
    Sentry.init({
        environment: process.env.REACT_APP_SENTRY_NODE_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ['https://www.app.renderjuice.com/'],
            }),
            Sentry.replayIntegration(),
        ],
        beforeSend(event, hint) {
            if (event.exception && event.event_id) {
                // On uncaught errors, show sentry's report dialog
                // but require minimal fields
                Sentry.showReportDialog({
                    eventId: event.event_id,
                })
            }
            return event
        },
        tracesSampleRate: Number.parseFloat(
            process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
        ),
        replaysSessionSampleRate: Number.parseFloat(
            process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
        ),
        replaysOnErrorSampleRate: Number.parseFloat(
            process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
        ),
    })
}

if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
        rootHostname: 'renderjuice.com',
        console: {
            isEnabled: true,
        },
    })
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <PersistGate loading={null} persistor={persistor}>
                    <>
                        <App />
                        <ToastContainer />
                    </>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

downZip.register()
