import { createSlice } from '@reduxjs/toolkit'

import { transactions } from '../../mocks/transactions'

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: transactions,
    },
})

export const selectTransactions = (state) => state.transactions.transactions

export default transactionsSlice.reducer
