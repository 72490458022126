import cn from 'classnames'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Switch from '../../../components/Switch'
import Tooltip from '../../../components/Tooltip'
import Item from '../Item'
import styles from './ExperimentalFeatures.module.sass'

const settings = [
    {
        id: 0,
        title: 'Color Management Presets and ACES',
        tooltip: 'Upload custom color configurations to use in your projects.',
    },
    {
        id: 1,
        title: 'Blender Add-Ons',
        tooltip:
            'Enable Blender add-ons and extensions to use in your projects.',
    },
    {
        id: 2,
        title: 'Dynamic RAM',
        tooltip:
            'Enable RAM usage over 124GB by combining render nodes at slight expense of speed.',
    },
    {
        id: 3,
        title: 'Team Accounts',
        tooltip: 'Enable team accounts to share renders, minutes, and more.',
    },
    {
        id: 4,
        title: 'Advanced Workflows & Pipelines',
        tooltip:
            'Add more control to your render pipelines to hook into your studio.',
    },
    {
        id: 5,
        title: 'Blender Version Pinning',
        tooltip: 'Enforce a very specific Blender build for more stability.',
    },
]

const Disclosure = styled.h3`
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.SONIC_SILVER};
`

const Notifications = ({ className }) => {
    const [selectedFilters, setSelectedFilters] = useState([])

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id))
        } else {
            setSelectedFilters((selectedFilters) => [...selectedFilters, id])
        }
    }

    return (
        <Item
            className={cn(styles.card, className)}
            title='Experimental Features 🥤'
            classTitle='title-red'
        >
            <div>
                <Disclosure>
                    As enticing as it may look, this section is actively being
                    worked on and does absolutely nothing at the moment.
                </Disclosure>
            </div>
            <div className={styles.list}>
                {settings.map((x, index) => (
                    <div className={styles.line} key={index}>
                        <div className={styles.title}>
                            {x.title}{' '}
                            <Tooltip
                                className={styles.tooltip}
                                title={x.tooltip}
                                icon='info'
                                place='top'
                            />
                        </div>
                        <Switch
                            className={styles.switch}
                            value={selectedFilters.includes(x.id)}
                            onChange={() => handleChange(x.id)}
                        />
                    </div>
                ))}
            </div>
        </Item>
    )
}

export default Notifications
