import axios from 'axios'
import { clearAuthState } from '../store/slices/authSlice'

class API {
    constructor(store, axiosArgs) {
        this.store = store
        this.axios = axios.create(axiosArgs)

        this.successInterceptor = this.successInterceptor.bind(this)
        this.errorInterceptor = this.errorInterceptor.bind(this)

        this.axios.interceptors.response.use(
            this.successInterceptor,
            this.errorInterceptor,
        )
    }

    successInterceptor(response) {
        return response.data
    }

    errorInterceptor(error) {
        if (error.response) {
            if (error.response.status === 401) {
                this.store.dispatch(clearAuthState())
                throw new Error(error.response.statusText || '401 Unauthorized')
            }
            throw new Error(
                error.response.data?.error?.message ||
                    `Request ${error.response.config.method.toUpperCase()} ${
                        error.response.config.url
                    } failed with status code: ${error.response.status}`,
            )
        }
        throw new Error('No response')
    }

    setAuthorization() {
        this.axios.defaults.headers.common.Authorization = `Bearer ${
            this.store.getState().auth.authToken
        }`
    }

    get(...args) {
        this.setAuthorization()
        return this.axios.get(...args)
    }

    post(...args) {
        this.setAuthorization()
        return this.axios.post(...args)
    }

    patch(...args) {
        this.setAuthorization()
        return this.axios.patch(...args)
    }

    put(...args) {
        this.setAuthorization()
        return this.axios.put(...args)
    }

    delete(...args) {
        this.setAuthorization()
        return this.axios.delete(...args)
    }
}

export default API
