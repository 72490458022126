import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './customFetchBase'

export const usersApi = createApi({
    reducerPath: 'subscriptionsApi',
    baseQuery: baseQuery,
    tagTypes: ['subscription'],
    endpoints: (builder) => ({
        getUserSubscription: builder.query({
            query: () => '/users/me?include=tier',
            transformResponse: (response) => {
                return response
            },
        }),
        getCreditsInfo: builder.query({
            query: () => '/users/me',
            transformResponse: (response) => {
                return response
            },
        }),
        getStripeSession: builder.query({
            query: () => '/users/me/stripe-session',
            transformResponse: (response) => {
                return response
            },
        }),
        getIntercomHash: builder.query({
            query: () => '/users/me/intercomhash',
            transformResponse: (response) => {
                return response
            },
        }),
        getUserSceneFiles: builder.query({
            query: (limit) => `/users/me/scene-files?limit=${limit}`,
            transformResponse: (response) => {
                return response
            },
        }),
        updateUserSceneFileSize: builder.mutation({
            query: (data) => ({
                url: '/users/me/scene-files',
                method: 'PATCH',
                body: data,
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        getPlans: builder.query({
            query: () => '/plans',
            transformResponse: (response) => {
                return response
            },
        }),
        changeOnDemandBudget: builder.mutation({
            query: (data) => ({
                url: '/users/me',
                method: 'PATCH',
                body: data,
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        purchaseGpuMinutes: builder.mutation({
            query: (data) => ({
                url: '/users/me/gpu-minutes/purchase',
                method: 'POST',
                body: data,
            }),
            transformResponse: (response) => {
                return response
            },
        }),
    }),
})

export const {
    useGetUserSubscriptionQuery,
    useGetCreditsInfoQuery,
    useGetStripeSessionQuery,
    useGetIntercomHashQuery,
    useGetUserSceneFilesQuery,
    useUpdateUserSceneFileSizeMutation,
    useGetPlansQuery,
    useChangeOnDemandBudgetMutation,
    usePurchaseGpuMinutesMutation,
} = usersApi
