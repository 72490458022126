import cn from 'classnames'
import React from 'react'
import styles from './Loader.module.sass'

const Loader = ({ className, white }) => {
    return (
        <div
            className={cn(styles.loader, className, {
                [styles.loaderWhite]: white,
            })}
        />
    )
}

export default Loader
