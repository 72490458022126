export const refunds = [
    {
        id: 0,
        product: 'Bento Matte 3D Illustration',
        category: 'UI design kit',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        status: true,
        date: '13 Sep',
        man: 'Kyler Kuphal',
        avatar: '/images/content/avatar-1.jpg',
        parameters: [
            {
                title: 'Request send',
                content: 'Aug 20, 2021',
            },
            {
                title: 'Reason',
                content: 'Download link is broken',
            },
            {
                title: 'Product downloaded',
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: 'Purchase date',
                content: 'July 01, 2021',
            },
            {
                title: 'Purchase code',
                content: '6373ads-hd73h-8373DS',
            },
            {
                title: 'Request ID',
                content: '8975ads-hd73h-8974DS',
            },
            {
                title: 'Market fee',
                tooltip: 'Description Market fee',
                price: 7.28,
            },
            {
                title: 'Price',
                tooltip: 'Description Price',
                price: 72.88,
            },
        ],
    },
    {
        id: 1,
        product: 'Node - Crypto iOS',
        category: 'UI design kit',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        status: false,
        date: '12 Sep',
        man: 'Eldred Lockman',
        avatar: '/images/content/avatar-2.jpg',
        parameters: [
            {
                title: 'Request send',
                content: 'Aug 20, 2021',
            },
            {
                title: 'Reason',
                content: 'Download link is broken',
            },
            {
                title: 'Product downloaded',
                downloadedStatus: true,
                downloadedValue: false,
            },
            {
                title: 'Purchase date',
                content: 'July 01, 2021',
            },
            {
                title: 'Purchase code',
                content: '6373ads-hd73h-8373DS',
            },
            {
                title: 'Request ID',
                content: '8975ads-hd73h-8974DS',
            },
            {
                title: 'Market fee',
                tooltip: 'Description Market fee',
                price: 7.28,
            },
            {
                title: 'Price',
                tooltip: 'Description Price',
                price: 72.88,
            },
        ],
    },
    {
        id: 2,
        product: 'TaskEz: Productivity App iOS',
        category: 'UI design kit',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        status: true,
        date: '11 Sep',
        man: 'Carlo Herman',
        avatar: '/images/content/avatar-3.jpg',
        parameters: [
            {
                title: 'Request send',
                content: 'Aug 20, 2021',
            },
            {
                title: 'Reason',
                content: 'Download link is broken',
            },
            {
                title: 'Product downloaded',
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: 'Purchase date',
                content: 'July 01, 2021',
            },
            {
                title: 'Purchase code',
                content: '6373ads-hd73h-8373DS',
            },
            {
                title: 'Request ID',
                content: '8975ads-hd73h-8974DS',
            },
            {
                title: 'Market fee',
                tooltip: 'Description Market fee',
                price: 7.28,
            },
            {
                title: 'Price',
                tooltip: 'Description Price',
                price: 72.88,
            },
        ],
    },
    {
        id: 3,
        product: 'Fleet - Travel shopping',
        category: 'UI design kit',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        status: false,
        date: '10 Sep',
        man: 'Camille Koss',
        avatar: '/images/content/avatar-4.jpg',
        parameters: [
            {
                title: 'Request send',
                content: 'Aug 20, 2021',
            },
            {
                title: 'Reason',
                content: 'Download link is broken',
            },
            {
                title: 'Product downloaded',
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: 'Purchase date',
                content: 'July 01, 2021',
            },
            {
                title: 'Purchase code',
                content: '6373ads-hd73h-8373DS',
            },
            {
                title: 'Request ID',
                content: '8975ads-hd73h-8974DS',
            },
            {
                title: 'Market fee',
                tooltip: 'Description Market fee',
                price: 7.28,
            },
            {
                title: 'Price',
                tooltip: 'Description Price',
                price: 72.88,
            },
        ],
    },
    {
        id: 4,
        product: 'Academe 3D Education Icons',
        category: 'UI design kit',
        image: '/images/content/product-pic-5.jpg',
        image2x: '/images/content/product-pic-5@2x.jpg',
        status: true,
        date: '9 Sep',
        man: 'Reyna Nikolaus',
        avatar: '/images/content/avatar-5.jpg',
        parameters: [
            {
                title: 'Request send',
                content: 'Aug 20, 2021',
            },
            {
                title: 'Reason',
                content: 'Download link is broken',
            },
            {
                title: 'Product downloaded',
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: 'Purchase date',
                content: 'July 01, 2021',
            },
            {
                title: 'Purchase code',
                content: '6373ads-hd73h-8373DS',
            },
            {
                title: 'Request ID',
                content: '8975ads-hd73h-8974DS',
            },
            {
                title: 'Market fee',
                tooltip: 'Description Market fee',
                price: 7.28,
            },
            {
                title: 'Price',
                tooltip: 'Description Price',
                price: 72.88,
            },
        ],
    },
]
