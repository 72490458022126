import { useAuth0 } from '@auth0/auth0-react'
import cn from 'classnames'
import React, { useState } from 'react'
import { useGetUserSubscriptionQuery } from 'store/api/usersApi'
import styled from 'styled-components'
import Editor from '../../../components/Editor'
import Icon from '../../../components/Icon'
import TextInput from '../../../components/TextInput'
import Item from '../Item'
import styles from './ProfileInformation.module.sass'

const StyledVIPBadge = styled.h3`
    background: linear-gradient(to bottom, #6A52FF, #AA69FF, #EEEEEE);
    padding: 0.5px 4px;
    color: #0C0C0C;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-weight: 500;
    border-radius: 4px;
    width: fit-content;
`

const VipContent = () => {
    return (
        <div>
            <StyledHeader>
                <StyledVIPBadge>VIP</StyledVIPBadge>
                <h4>Thank you for being an early supporter.</h4>
            </StyledHeader>
            <div>
                <h2>Perks as a token of our gratitude:</h2>
                <StyledList>
                    <StyledListItem>
                        - If you're on our early access pricing model, your
                        subscription is currently locked into its current price
                        until we deem this to be impractical. However, your
                        subscription will revert to regular pricing if you
                        decide to cancel or downgrade and want to resubscribe.
                        For upgrades, please reach out and we can see what we
                        can do.
                        <br />
                    </StyledListItem>
                    <li>
                        - You may reach out at any time to check if there are
                        any additional perks available.
                    </li>
                    <li>
                        - As a VIP user, variable discount codes will be
                        available below. Simply add the first 3 characters of
                        your email used for your account to the code(s) below:
                    </li>
                </StyledList>
                <br />
                <StyledListItem>
                    <StyledCode>VIPEAxxx</StyledCode>
                    (minimum spend is $50, please add the first 3 characters of
                    your email to the end of the code)
                </StyledListItem>
            </div>
        </div>
    )
}

const NoPerksContent = () => {
    return (
        <div>
            <StyledMessage>
                <h4>No perks available on your account yet! </h4>
                <h4>Hopefully soon!</h4>
            </StyledMessage>
        </div>
    )
}

const StyledMessage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
`

const ProfileInformation = ({ className }) => {
    const [content, setContent] = useState()
    const { data: meData } = useGetUserSubscriptionQuery()
    const isVip = meData?.badges.includes('earlyAccess')
    return (
        <Item
            className={cn(styles.card, className)}
            title='Profile & Special Perks'
            classTitle='title-green'
        >
            {isVip ? <VipContent /> : <NoPerksContent />}
            <div className={styles.profile}>
                {/*
                <div className={styles.avatar}>
                    <img
                        src={
                            user ? user?.picture : '/images/content/avatar.jpg'
                        }
                        alt='Avatar'
                    />
                    <button className={styles.remove}>
                        <Icon name='close' />
                    </button>
                </div>
                <div className={styles.file}>
                    <input type='file' />
                    <button
                        className={cn('button', styles.button)}
                        type='button'
                    >
                        <Icon name='add' size='24' />
                        <span>Upload new picture</span>
                    </button>
                </div>
                <button className={cn('button-stroke', styles.button)}>
                    Remove
                </button>
                */}
            </div>
            <div className={styles.fieldset}>
                {/*
                <TextInput
                    className={styles.field}
                    label='Display name'
                    name='display-name'
                    type='text'
                    tooltip='Maximum 100 characters. No HTML or emoji allowed'
                    required
                />
                <TextInput
                    className={styles.field}
                    label='Email'
                    name='email'
                    type='email'
                    tooltip='Maximum 100 characters. No HTML or emoji allowed'
                    required
                />
                <TextInput
                    className={styles.field}
                    label='Location'
                    name='location'
                    type='text'
                    tooltip='Maximum 100 characters. No HTML or emoji allowed'
                    required
                />
                <Editor
                    state={content}
                    onChange={setContent}
                    classEditor={styles.editor}
                    label='Bio'
                    tooltip='Description'
                />
                */}
            </div>
        </Item>
    )
}

// Styled components
const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`

const StyledList = styled.ol`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const StyledListItem = styled.li`
    list-style: none;
`

const StyledCode = styled.code`
    color: #6A52FF;
    margin-right: 5px;
`

export default ProfileInformation
