import { createSlice } from '@reduxjs/toolkit'

const displaySlice = createSlice({
    name: 'display',
    initialState: {
        isSidebarExpanded: true,
    },
    reducers: {
        toggleSidebar: (state) => {
            state.isSidebarExpanded = !state.isSidebarExpanded
        },
    },
})

export const { toggleSidebar } = displaySlice.actions

export default displaySlice.reducer
