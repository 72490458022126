import { progress } from '../utils'

export const market = [
    {
        id: 0,
        product: 'Bento Matte 3D Illustration',
        category: 'UI design kit',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        status: true,
        price: 98,
        sales: 3200,
        balance: 37.8,
        views: 79850,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 111,
    },
    {
        id: 1,
        product: 'DSM - Geometry pattern',
        category: 'UI design kit',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        status: false,
        price: 48,
        sales: 2732,
        balance: 27.5,
        views: 30432,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 17,
    },
    {
        id: 2,
        product: 'Node - Crypto iOS UI design kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        status: true,
        price: 98,
        sales: 800,
        balance: 10.8,
        views: 79850,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 30,
    },
    {
        id: 3,
        product: 'TaskEz: Productivity App iOS UI Kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        status: false,
        price: 48,
        sales: 3000,
        balance: -27.5,
        views: 13213,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 8,
    },
    {
        id: 4,
        product: 'Academe 3D Education Icons',
        category: 'UI design kit',
        image: '/images/content/product-pic-5.jpg',
        image2x: '/images/content/product-pic-5@2x.jpg',
        status: true,
        price: 48,
        sales: 2400,
        balance: 27.5,
        views: 42342,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 23,
    },
    {
        id: 5,
        product: 'Bento Matte 3D Illustration',
        category: 'UI design kit',
        image: '/images/content/product-pic-6.jpg',
        image2x: '/images/content/product-pic-6@2x.jpg',
        status: true,
        price: 98,
        sales: 3200,
        balance: 37.8,
        views: 79850,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 111,
    },
    {
        id: 6,
        product: 'DSM - Geometry pattern',
        category: 'UI design kit',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        status: false,
        price: 48,
        sales: 2732,
        balance: 27.5,
        views: 30432,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 17,
    },
    {
        id: 7,
        product: 'Bento Matte 3D Illustration',
        category: 'UI design kit',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        status: true,
        price: 98,
        sales: 800,
        balance: 10.8,
        views: 79850,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 30,
    },
    {
        id: 8,
        product: 'Node - Crypto iOS UI design kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        status: false,
        price: 48,
        sales: 3000,
        balance: -27.5,
        views: 13213,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 8,
    },
    {
        id: 9,
        product: 'TaskEz: Productivity App iOS UI Kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        status: true,
        price: 48,
        sales: 2400,
        balance: 27.5,
        views: 42342,
        viewsPercent: progress(),
        likesPercent: progress(),
        likes: 23,
    },
]

export const released = [
    {
        id: 0,
        product: 'Bento Matte 3D Illustration',
        category: 'UI design kit',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        price: 98,
        status: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        sales: 3200,
        balance: 55.8,
        views: 47850,
        viewsPercent: progress(),
    },
    {
        id: 1,
        product: 'DSM - Geometry pattern',
        category: 'UI design kit',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        price: 123,
        status: false,
        ratingValue: 4.6,
        ratingCounter: 127,
        sales: 4900,
        balance: 35.8,
        views: 32500,
        viewsPercent: progress(),
    },
    {
        id: 2,
        product: 'Node - Crypto iOS UI design kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        price: 0,
        status: true,
        sales: 5600,
        balance: -15.8,
        views: 55500,
        viewsPercent: progress(),
    },
    {
        id: 3,
        product: 'TaskEz: Productivity App iOS UI Kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        price: 33,
        status: false,
        ratingValue: 4.9,
        ratingCounter: 7,
        sales: 1100,
        balance: 19.2,
        views: 39500,
        viewsPercent: progress(),
    },
    {
        id: 4,
        product: 'Academe 3D Education Icons',
        category: 'UI design kit',
        image: '/images/content/product-pic-5.jpg',
        image2x: '/images/content/product-pic-5@2x.jpg',
        price: 45,
        status: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        sales: 3200,
        balance: 55.8,
        views: 47850,
        viewsPercent: progress(),
    },
]
