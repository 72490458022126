import { createSlice } from '@reduxjs/toolkit'

import { refunds } from '../../mocks/refunds'

const refundsSlice = createSlice({
    name: 'refunds',
    initialState: {
        refunds: refunds,
    },
})

export const selectRefunds = (state) => state.refunds.refunds

export default refundsSlice.reducer
