import { createSlice } from '@reduxjs/toolkit'

import { posts } from '../../mocks/posts'

const postsSlice = createSlice({
    name: 'posts',
    initialState: {
        posts: posts,
    },
})

export const selectPosts = (state) => state.posts.posts

export default postsSlice.reducer
