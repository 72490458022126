import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const uploadRenderFileSlice = createSlice({
    name: 'uploadRenderFile',
    initialState: {
        file: null,
        status: 'idle',
    },

    reducers: {
        addFile: (state, action) => {
            state.file = action.payload
        },
        deleteFile: (state) => {
            state.file = null
        },
    },
})

export const { addFile, deleteFile } = uploadRenderFileSlice.actions

export const selectFile = (state) => state.uploadRenderFile.file

export default uploadRenderFileSlice.reducer
