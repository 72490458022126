import { createSlice } from '@reduxjs/toolkit'

import { followers } from '../../mocks/followers'

const followersSlice = createSlice({
    name: 'followers',
    initialState: {
        followers: followers,
    },
})

export const selectFollowers = (state) => state.followers.followers

export default followersSlice.reducer
