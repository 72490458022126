import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useGetUserSubscriptionQuery } from 'store/api/usersApi'
import { selectAuthToken } from 'store/slices/authSlice'
import styled from 'styled-components'
import analytics from 'utils/analytics'

const StyledLoader = styled(Loader)`
  margin: auto;
`

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoadingSubcontainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -240px;
  gap: 40px;
  align-items: center;
  justify-content: center;
`

export const ProtectedRoute = ({ component, ...args }) => {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const sessionIDFromURL = query.get('session_id')
    const {
        isAuthenticated,
        isLoading: isAuthLoading,
        loginWithRedirect,
    } = useAuth0()

    useEffect(() => {
        if (!isAuthenticated && !isAuthLoading) {
            loginWithRedirect()
        }
        if (typeof sessionIDFromURL === 'string' && sessionIDFromURL !== '') {
            analytics.setAnonymousId(sessionIDFromURL)
        }
    }, [isAuthenticated, isAuthLoading, loginWithRedirect, sessionIDFromURL])

    if (isAuthLoading) {
        return (
            <Container>
                <LoadingSubcontainer>
                    <h3>Loading...</h3>
                    <StyledLoader />
                </LoadingSubcontainer>
            </Container>
        )
    }

    if (!isAuthenticated && !isAuthLoading) {
        return (
            <Container>
                <LoadingSubcontainer>
                    <h3>Redirecting to login...</h3>
                    <StyledLoader />
                    <p>
                        If you are not redirected automatically, click the
                        button below.
                    </p>
                    <Button
                        variant='primary'
                        onClick={() => loginWithRedirect()}
                    >
                        Go to Login
                    </Button>
                </LoadingSubcontainer>
            </Container>
        )
    }

    return <Route component={component} {...args} />
}

const Loading = () => (
    <Container>
        <LoadingSubcontainer>
            <h3>Loading...</h3>
            <StyledLoader />
        </LoadingSubcontainer>
    </Container>
)

/*
    This is the alternative route that uses the withAuthenticationRequired HOC
    We can use this after upgrading react-router and auth0 packages
*/
export const ProtectedRouteAlternative = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loading />,
        })}
        {...args}
    />
)

export const SubscribedRoute = ({ component, ...args }) => {
    const authToken = useSelector(selectAuthToken)
    const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth0()
    const {
        data,
        isLoading: isSubscriptionLoading,
        refetch,
    } = useGetUserSubscriptionQuery()

    useEffect(() => {
        if (isAuthenticated && authToken && !data) {
            refetch()
        }
    }, [isAuthenticated, authToken, data, refetch])

    // If Auth0 or subscription data is loading or if the user is authenticated but there's no data yet.
    if (
        isAuthLoading ||
        (isAuthenticated && (!authToken || !data)) ||
        isSubscriptionLoading
    ) {
        return (
            <Container>
                <StyledLoader />
            </Container>
        )
    }

    if (!isAuthenticated) {
        return <Redirect to='/login' />
    }

    if (data?.tier && data?.stripeCustomerId) {
        return <Route component={component} {...args} />
    }

    return (
        <Redirect
            to={{
                pathname: '/upgrade-to-pro',
                state: { from: args.path },
            }}
        />
    )
}
