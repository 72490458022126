import { AnalyticsBrowser } from '@segment/analytics-next'

const analytics = new AnalyticsBrowser()
const EVENTS = {
    VISIT_CHECKOUT: 'Visit Checkout',
    UPLOAD_FILE: 'Upload File',
    CHECKOUT_COMPLETED: 'Checkout Completed',
    CHECKOUT_CANCELLED: 'Checkout Cancelled',
    START_VALIDATION: 'Start Validation',
    START_RENDERING: 'Start Rendering',
    CANCEL_RENDERING: 'Cancel Rendering',
    CANCEL_VALIDATION: 'Cancel Validation',
    DOWNLOAD_FILE: 'Download File',
    VISIT_BILLING_PAGE: 'Visit Billing Page',
    VISIT_INTERCOM_ARTICLE: 'Visit Intercom Article',
    PUT_RENDER_CONFIGURATIONS: 'Put Render Configurations',
    LOGOUT: 'Logout',
    OPEN_PREVIEW_FRAME_MODAL: 'Open Preview Frame Modal',
    RESUME_JOB: 'Resume Job',
    FEEDBACK_HAPPY_CLICK: 'Feedback Happy Click',
    FEEDBACK_SAD_CLICK: 'Feedback Sad Click',
    UPLOAD_ADDON: 'Upload Addon',
}

export { EVENTS }
export default analytics
