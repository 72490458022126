export const comments = [
    {
        id: 0,
        product: 'DSM - Geometry pattern',
        category: 'UI design kit',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        time: '8h',
        author: 'Samson Heathcote',
        avatar: '/images/content/avatar-1.jpg',
        comment: 'Awesome, keep it up',
    },
    {
        id: 1,
        product: 'Node - Crypto iOS UI design kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        time: '14h',
        author: 'Maureen Russel',
        avatar: '/images/content/avatar-2.jpg',
        comment:
            'Dude, I am unable to download, please check the link. <span role="img" aria-label="smile">😢</span>',
    },
    {
        id: 2,
        product: 'TaskEz: Productivity App iOS UI Kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        time: '16h',
        author: 'Whitney Nicolas',
        avatar: '/images/content/avatar-3.jpg',
        comment: 'Awesome, keep it up',
    },
    {
        id: 3,
        product: 'Bitcloud - Crypto exchange UI kit',
        category: 'UI design kit',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        time: '19h',
        author: 'Amani Rempel',
        avatar: '/images/content/avatar-4.jpg',
        comment: 'Awesome, keep it up',
    },
    {
        id: 4,
        product: 'Academe 3D Education Icons',
        category: 'UI design kit',
        image: '/images/content/product-pic-5.jpg',
        image2x: '/images/content/product-pic-5@2x.jpg',
        time: '1day',
        author: 'Corene Toy',
        avatar: '/images/content/avatar-5.jpg',
        comment: 'Awesome, keep it up',
    },
]

export const commentsProduct = [
    {
        id: 0,
        author: 'Stephon Schumm',
        avatar: '/images/content/avatar-1.jpg',
        login: '@ivawelch',
        time: '2h',
        rating: 5,
        comment:
            'Purchased the pack and loaded it in Adobe xd (installed fonts as well), some text and titles are off in some screens, is that a compatibility problem with the latest version of xd?',
        answer: [
            {
                id: 1,
                author: 'Chelsie Haley',
                avatar: '/images/content/avatar.jpg',
                time: '18h',
                rating: 4,
                comment:
                    'Hi <strong>@ivawelch</strong>, let me check then tell you soon.',
            },
        ],
    },
    {
        id: 2,
        author: 'Sabryna Mills',
        avatar: '/images/content/avatar-2.jpg',
        login: '@ivawelch',
        time: '15h',
        rating: 5,
        comment: 'Five stars. I love this product!',
    },
    {
        id: 3,
        author: 'Micaela Mayer',
        avatar: '/images/content/avatar-4.jpg',
        login: '@ivawelch',
        time: '1 day ago',
        rating: 5,
        comment:
            'Purchased the pack and loaded it in Adobe xd (installed fonts as well), some text and titles are off in some screens, is that a compatibility problem with the latest version of xd?',
    },
    {
        id: 4,
        author: 'Daphne Goodwin',
        avatar: '/images/content/avatar-3.jpg',
        login: '@ivawelch',
        time: '2 days ago',
        rating: 5,
        comment:
            'Purchased the pack and loaded it in Adobe xd (installed fonts as well), some text and titles are off in some screens, is that a compatibility problem with the latest version of xd?',
    },
]
