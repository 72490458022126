export const numberWithCommas = (x) => {
    const parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
}

export const progress = () => {
    return `${Math.floor(Math.random() * 90) + 10}%`
}

export function round(num, decimalPlaces = 0) {
    const p = 10 ** decimalPlaces
    const n = num * p * (1 + Number.EPSILON)
    return Math.round(n) / p
}

export const getPaymentURL = (linkId) => {
    return `${process.env.REACT_APP_CHECKOUT_URL}/${linkId}`
}
