import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getProducts = createAsyncThunk(
    'products/getProducts',
    async (_, { extra: { api }, rejectWithValue }) => {
        try {
            const products = await api.get('/products')
            return products.data
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (
        { title, description, price, currency, billingPeriod },
        { extra: { api }, rejectWithValue },
    ) => {
        try {
            await api.post('/products', {
                title,
                description,
                price,
                currency,
                billingPeriod,
            })
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (id, { extra: { api }, rejectWithValue }) => {
        try {
            await api.delete(`/products/${id}`)
            return id
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        isLoaded: false,
    },
    reducers: {
        addProduct: (state, action) => {
            state.products = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoaded = true
                state.products = action.payload
            })
            .addCase(createProduct.fulfilled, (state) => {
                state.isLoaded = false
                state.products = []
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.products = state.products.filter(
                    (product) => product.id !== action.payload,
                )
            })
    },
})

export const { addProduct } = productsSlice.actions

export const selectProducts = (state) => state.products.products

export default productsSlice.reducer
