import { createSlice } from '@reduxjs/toolkit'

import { creators } from '../../mocks/creators'

const creatorsSlice = createSlice({
    name: 'creators',
    initialState: {
        creators: creators,
    },
})

export const selectCreators = (state) => state.creators.creators

export default creatorsSlice.reducer
