import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import analytics from 'utils/analytics'
import Router from './router'
import './styles/app.sass'

analytics
    .load({ writeKey: process.env.REACT_APP_SEGMENT_KEY })
    .catch((err) => console.error('Failed to load analytics', err))

function App() {
    return (
        <Sentry.ErrorBoundary
            showDialog={true}
            beforeCapture={(scope) => {
                scope.setTag('screen', 'App')
            }}
        >
            <Router />
        </Sentry.ErrorBoundary>
    )
}

export default App
