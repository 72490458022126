import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const showModal = createAsyncThunk('modal/showModal', (payload) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(payload)
        }, 300)
    })
})

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        visible: false,
        message: '',
        closeIcon: true,
    },
    reducers: {
        hideModal: (state) => {
            state.visible = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(showModal.fulfilled, (state, action) => {
            state.visible = true
            state.message = action.payload
        })
    },
})

export const { hideModal } = modalSlice.actions

export const selectModal = (state) => state.modal.modal

export default modalSlice.reducer
