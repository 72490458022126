import cn from 'classnames'
import React from 'react'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import styles from './TextInput.module.sass'

const TextInput = ({
    className,
    classLabel,
    classInput,
    label,
    icon,
    copy,
    currency,
    tooltip,
    place,
    required,
    ...props
}) => {
    return (
        <div
            className={cn(
                styles.field,
                { [styles.fieldIcon]: icon },
                { [styles.fieldCopy]: copy },
                { [styles.fieldCurrency]: currency },
                className,
            )}
        >
            {label && (
                <div className={cn(classLabel, styles.label)}>
                    {label}{' '}
                    {required && <span style={{ color: '#ff6a55' }}>* </span>}
                    {tooltip && (
                        <Tooltip
                            className={styles.tooltip}
                            title={tooltip}
                            icon='info'
                            place={place ? place : 'right'}
                        />
                    )}
                </div>
            )}
            <div className={styles.wrap}>
                <input className={cn(classInput, styles.input)} {...props} />
                {icon && (
                    <div className={styles.icon}>
                        <Icon name={icon} size='24' />{' '}
                    </div>
                )}
                {copy && (
                    <button className={styles.copy}>
                        <Icon name='copy' size='24' />{' '}
                    </button>
                )}
                {currency && <div className={styles.currency}>{currency}</div>}
            </div>
        </div>
    )
}

export default TextInput
