export const getFileNameFromUrl = (url) => {
    // Split the URL by the slash character to get segments
    const segments = url.split('/')

    // Get the last segment, which may contain the filename and potential query parameters
    const potentialFilename = segments[segments.length - 1]

    // Split the potential filename by the question mark to separate the filename from the query parameters
    const filenameSegments = potentialFilename.split('?')

    // Return the decoded filename (before the question mark)
    return decodeURIComponent(filenameSegments[0])
}

export const isImageUrl = (url) => {
    if (!url) return false
    const imageExtensions = ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff', 'webp']

    // Extracting part of URL before '?'
    const baseUrl = url.split('?')[0]
    const extension = baseUrl.split('.').pop().toLowerCase()

    return imageExtensions.includes(extension)
}

export const isVideoUrl = (url) => {
    // add more video extensions

    const videoExtensions = [
        'mp4',
        'webm',
        'ogg',
        'mov',
        'avi',
        'wmv',
        'flv',
        'mkv',
        'm4v',
        'm4p',
        'm4b',
        'm4r',
        '3gp',
        '3g2',
        'aac',
        'm4a',
        'mp3',
        'wav',
        'flac',
        'alac',
        'wma',
        'aiff',
        'ape',
        'dsd',
        'dff',
        'dsf',
        'mpc',
        'aac',
        'ac3',
        'aiff',
        'amr',
        'ape',
        'dts',
        'flac',
        'm4a',
        'mka',
        'mp1',
        'mp2',
        'mp3',
        'ogg',
        'opus',
        'ra',
        'tta',
        'wav',
        'wma',
        'wv',
        'ogv',
        'dv',
        'mp4',
        'mpg',
        'mpeg',
        'vob',
        'dvd',
    ]

    // Extracting part of URL before '?'
    const baseUrl = url.split('?')[0]
    const extension = baseUrl.split('.').pop().toLowerCase()

    return videoExtensions.includes(extension)
}
