import { createSlice } from '@reduxjs/toolkit'

import { traffic } from '../../mocks/traffic'

const trafficSlice = createSlice({
    name: 'traffic',
    initialState: {
        traffic: traffic,
    },
})

export const selectTraffic = (state) => state.traffic.traffic

export default trafficSlice.reducer
