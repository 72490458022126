import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const VERSION_URL =
    'https://public-assets-renderjuice.s3.amazonaws.com/versions.json'

export const metaApi = createApi({
    reducerPath: 'metaApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getVersion: builder.query({
            query: () => VERSION_URL,
            transformResponse: (response) => response,
        }),
    }),
})

export const { useGetVersionQuery, middleware, reducerPath } = metaApi
