import cn from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Header from '../Header'
import Sidebar from '../Sidebar'
import styles from './Page.module.sass'

const Page = ({ children }) => {
    const [visible, setVisible] = useState(false)
    const { isSidebarExpanded } = useSelector((state) => state.display)

    return (
        <>
            <div
                className={cn(styles.page, {
                    [styles.toggleSidebarDesktop]: !isSidebarExpanded,
                })}
            >
                <Sidebar
                    className={cn(styles.sidebar, {
                        [styles.visible]: visible,
                    })}
                    onClose={() => setVisible(false)}
                />
                <Header onOpen={() => setVisible(true)} />
                <div className={styles.inner}>{children}</div>
            </div>
        </>
    )
}

export default withRouter(Page)
