import { createSlice } from '@reduxjs/toolkit'

import { history } from '../../mocks/history'

const purchaseHistorySlice = createSlice({
    name: 'purchaseHistory',
    initialState: {
        purchaseHistory: history,
    },
})

export const selectPurchaseHistory = (state) =>
    state.purchaseHistory.purchaseHistory

export default purchaseHistorySlice.reducer
