import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const createJob = createAsyncThunk(
    'job/createJob',
    async (jobData, { getState }) => {
        const { auth } = getState()
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/jobs`,
            jobData,
            { headers: { Authorization: `Bearer ${auth.authToken}` } },
        )
        return response.data
    },
)

export const createJobWithRecentFile = createAsyncThunk(
    'job/createJobWithRecentFile',
    async (jobData, { getState }) => {
        const { auth } = getState()
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/jobs`,
            jobData,
            { headers: { Authorization: `Bearer ${auth.authToken}` } },
        )
        return response.data
    },
)

const jobSlice = createSlice({
    name: 'job',
    initialState: {
        id: null,
        isRendering: false,
        isSuccess: false,
        status: 'idle',
        error: null,
    },
    reducers: {
        changeSuccessStatus: (state, action) => {
            state.isSuccess = action.payload
        },
        changeRenderingStatus: (state, action) => {
            state.isRendering = action.payload
        },
        setUploadProgress: (state, action) => {
            state.uploadProgress = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createJob.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createJob.fulfilled, (state, action) => {
                console.log('action', action)
                console.log('action.payload', action.payload)
                state.status = 'succeeded'
                state.id = action.payload.id
            })
            .addCase(createJob.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    },
})

export const { changeSuccessStatus, changeRenderingStatus, setUploadProgress } =
    jobSlice.actions

export const selectJob = (state) => state.job
export const selectJobId = (state) => state.job.id

export default jobSlice.reducer
