export const customers = [
    {
        id: 0,
        user: 'Chelsie Haley',
        login: '@username',
        avatar: '/images/content/avatar-1.jpg',
        email: 'chelsie@ui8.net',
        purchase: 4,
        price: 384,
        balance: -12.8,
        comments: 8,
        likes: 16,
    },
    {
        id: 1,
        user: 'Filomena Fahey',
        login: '@username',
        avatar: '/images/content/avatar-2.jpg',
        email: 'fahey.designer@robot.co',
        purchase: 12,
        price: 223,
        balance: 2.8,
        comments: 14,
        likes: 6,
    },
    {
        id: 2,
        user: 'Orion Luettgen',
        login: '@username',
        avatar: '/images/content/avatar-3.jpg',
        email: 'orion88@yahoo.com',
        purchase: 6,
        price: 546,
        balance: -5.9,
        comments: 34,
        likes: 28,
    },
    {
        id: 3,
        user: 'Brown Beatty',
        login: '@username',
        avatar: '/images/content/avatar-4.jpg',
        email: 'brown.be@gmail.com',
        purchase: 31,
        price: 98,
        balance: -25.9,
        comments: 14,
        likes: 42,
    },
    {
        id: 4,
        user: 'Bessie Runolfsson',
        login: '@username',
        avatar: '/images/content/avatar-5.jpg',
        email: 'bess@ui8.net',
        purchase: 31,
        price: 98,
        balance: 13.4,
        comments: 4,
        likes: 42,
    },
    {
        id: 5,
        user: 'Chelsie Haley',
        login: '@username',
        avatar: '/images/content/avatar-1.jpg',
        email: 'chelsie@ui8.net',
        purchase: 4,
        price: 384,
        balance: -12.8,
        comments: 8,
        likes: 16,
    },
    {
        id: 6,
        user: 'Filomena Fahey',
        login: '@username',
        avatar: '/images/content/avatar-2.jpg',
        email: 'fahey.designer@robot.co',
        purchase: 12,
        price: 223,
        balance: 2.8,
        comments: 14,
        likes: 6,
    },
    {
        id: 7,
        user: 'Orion Luettgen',
        login: '@username',
        avatar: '/images/content/avatar-3.jpg',
        email: 'orion88@yahoo.com',
        purchase: 6,
        price: 546,
        balance: -5.9,
        comments: 34,
        likes: 28,
    },
    {
        id: 8,
        user: 'Brown Beatty',
        login: '@username',
        avatar: '/images/content/avatar-4.jpg',
        email: 'brown.be@gmail.com',
        purchase: 31,
        price: 98,
        balance: -25.9,
        comments: 14,
        likes: 42,
    },
    {
        id: 9,
        user: 'Bessie Runolfsson',
        login: '@username',
        avatar: '/images/content/avatar-5.jpg',
        email: 'bess@ui8.net',
        purchase: 31,
        price: 98,
        balance: 13.4,
        comments: 4,
        likes: 42,
    },
]
