import { createSlice } from '@reduxjs/toolkit'

const rendersSlice = createSlice({
    name: 'renders',
    initialState: {
        renders: [],
    },
    reducers: {
        addRenders: (state, action) => {
            state.renders = action.payload
        },
    },
})

export const selectRenders = (state) => state.renders.renders

export default rendersSlice.reducer
