import cn from 'classnames'
import React, { useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import styled, { css } from 'styled-components'
import useDarkMode from 'use-dark-mode'
import Switch from '../../../components/Switch'
import Tooltip from '../../../components/Tooltip'
import Item from '../Item'
import styles from './PlatformSettings.module.sass'

const settings = [
    {
        id: 0,
        title: 'Color Management Presets and ACES',
        tooltip: 'Upload custom color configurations to use in your projects.',
    },
    {
        id: 1,
        title: 'Blender Add-Ons',
        tooltip:
            'Enable Blender add-ons and extensions to use in your projects.',
    },
    {
        id: 2,
        title: 'Dynamic RAM',
        tooltip:
            'Enable RAM usage over 124GB by combining render nodes at slight expense of speed.',
    },
    {
        id: 3,
        title: 'Team Accounts',
        tooltip: 'Enable team accounts to share renders, minutes, and more.',
    },
    {
        id: 4,
        title: 'Advanced Workflows & Pipelines',
        tooltip:
            'Add more control to your render pipelines to hook into your studio.',
    },
    {
        id: 5,
        title: 'Blender Version Pinning',
        tooltip: 'Enforce a very specific Blender build for more stability.',
    },
]

const Disclosure = styled.h3`
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.SONIC_SILVER};
`

const Notifications = ({ className }) => {
    const [selectedFilters, setSelectedFilters] = useState([])

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id))
        } else {
            setSelectedFilters((selectedFilters) => [...selectedFilters, id])
        }
    }

    const [dataStorage, setDataStorage] = useState(1)
    const STEP = 1
    const MIN = 1
    const MAX = 45
    const darkMode = useDarkMode(false)

    return (
        <Item
            className={cn(styles.card, className)}
            title='Platform Settings'
            classTitle='title-red'
        >
            <div>
                <Disclosure>
                    As enticing as it may look, this section is actively being
                    worked on and does absolutely nothing at the moment.
                </Disclosure>
            </div>
            <div className={styles.list}>
                <Range
                    values={[dataStorage]}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => {
                        setDataStorage(values[0])
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                width: '100%',
                                padding: '10px',
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '6px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: [7, 14, 30, 45],
                                        colors: [
                                            [
                                                '#FFFFFF 2%',
                                                '#A3CAFF',
                                                '#0353BF',
                                                '#B095FF',
                                                '#8E59FF',
                                            ],
                                            `${
                                                darkMode.value
                                                    ? '#272B30'
                                                    : '#EFEFEF'
                                            }`,
                                        ],
                                        min: MIN,
                                        max: MAX,
                                    }),
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ index, props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '16px',
                                width: '16px',
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                border: '2px solid #2A85FF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: 'inset 0px 2px 2px #FFFFFF',
                            }}
                        />
                    )}
                />
            </div>
        </Item>
    )
}

export default Notifications
