import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import apiAxios from '../../utils/apiAxios'

import { notifications } from '../../mocks/notifications'

export const postRenderNotification = createAsyncThunk(
    'notifications/postRenderNotification',
    async (payload) => {
        const response = await apiAxios.post('/internal/notifications')
    },
)

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: notifications,
    },
})

export const selectNotifications = (state) => state.notifications.notifications

export default notificationsSlice.reducer
