import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const baseUrl = `${process.env.REACT_APP_API_URL}/api`

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.authToken
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    },
})

export default baseQuery
