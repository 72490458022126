import { createSlice } from '@reduxjs/toolkit'

import { viewers } from '../../mocks/viewers'

const viewersSlice = createSlice({
    name: 'viewers',
    initialState: {
        viewers: viewers,
    },
})

export const selectViewers = (state) => state.viewers.viewers

export default viewersSlice.reducer
