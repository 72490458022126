export const creators = [
    {
        id: 0,
        creator: 'Reed Parker',
        avatar: '/images/content/avatar-1.jpg',
        colorNumber: '#B5E4CA',
        productsCounter: 16,
        followers: 3568,
        products: [
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
        ],
    },
    {
        id: 1,
        creator: 'Tristian Hermiston',
        avatar: '/images/content/avatar-2.jpg',
        colorNumber: '#FFD88D',
        productsCounter: 34,
        followers: 2345,
        products: [
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
        ],
    },
    {
        id: 2,
        creator: 'Rosetta Gottlieb',
        avatar: '/images/content/avatar-3.jpg',
        colorNumber: '#CABDFF',
        productsCounter: 9,
        followers: 13242,
        products: [
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
        ],
    },
    {
        id: 3,
        creator: 'Nicola Gislason',
        avatar: '/images/content/avatar-4.jpg',
        productsCounter: 22,
        followers: 1211,
        products: [
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
        ],
    },
    {
        id: 4,
        creator: 'Hester Grady',
        avatar: '/images/content/avatar-5.jpg',
        productsCounter: 31,
        followers: 9832,
        products: [
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
        ],
    },
]
