import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAuthState, addUserId } from 'store/slices/authSlice'

/**
 * @description synchronizes auth state with auth0 should
 * be used as close to root of app as possible
 * @returns {void}
 */
const useAuthMount = () => {
    const dispatch = useDispatch()
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently()
            dispatch(addAuthState(token))
            dispatch(addUserId(user.sub))
        }
    }, [isAuthenticated, getAccessTokenSilently, user, dispatch])

    useEffect(() => {
        const fetchToken = async () => {
            await getToken()
        }

        fetchToken()

        const interval = setInterval(fetchToken, 10000)

        return () => clearInterval(interval)
    }, [getToken])
}

export default useAuthMount
