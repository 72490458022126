import { createSlice } from '@reduxjs/toolkit'

import { customers } from '../../mocks/customers'

const customersSlice = createSlice({
    name: 'customers',
    initialState: {
        customers: customers,
    },
})

export const selectCustomers = (state) => state.customers.customers

export default customersSlice.reducer
