import { createSlice } from '@reduxjs/toolkit'

import { comments } from '../../mocks/comments'

const commentsSlice = createSlice({
    name: 'comments',
    initialState: {
        comments: comments,
    },
})

export const selectComments = (state) => state.comments.comments

export default commentsSlice.reducer
