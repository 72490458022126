import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const Signup = ({ options }) => {
    const { loginWithRedirect } = useAuth0()
    useEffect(
        () =>
            loginWithRedirect({
                screen_hint: 'signup',
                prompt: 'login',
            }),
        [loginWithRedirect],
    )

    return <h2>Signup</h2>
}

export default Signup
