import { createSlice } from '@reduxjs/toolkit'

// example structure of `outputCategories` looks like this:
// {
//   cameras: {
//     all: ["url1", "url2", "..."],
//     cameras: { // <-- nullable
//       camera1: ["url1", "url2", "..."],
//       camera2: ["url1", "url2", "..."],
//     },
//   },
//   nodes: {
//     all: ["nodeUrl1", "nodeUrl2", "..."],
//     nodes: {
//       node1: {
//         all: ["nodeUrl1", "nodeUrl2", "..."],
//         cameras: { // <-- nullable
//           camera1: ["nodeUrl1", "nodeUrl2", "..."],
//           camera2: ["nodeUrl1", "nodeUrl2", "..."],
//         },
//       },
//       node2: {
//         all: ["nodeUrl1", "nodeUrl2", "..."],
//         cameras: { // <-- nullable
//           camera1: ["nodeUrl1", "nodeUrl2", "..."],
//           camera2: ["nodeUrl1", "nodeUrl2", "..."],
//         },
//       },
//     }
//   },
// }
const initialState = {
    outputJob: null,
    outputCategories: { cameras: { all: [] }, nodes: { all: [] } }, // all outputs
    selectedOutputUrls: [], // filtered outputs
    selectedCategory: 'cameras', // for now either 'cameras' or 'nodes'
    selectedCamera: null, // if null then all cameras
    selectedNode: null, //if null then all nodes
    frameRange: { start: 0, end: 0 }, // default 0-0 means no frame range filter
}

const renderOutputsSlice = createSlice({
    name: 'renderOutputs',
    initialState,
    reducers: {
        setOutputJob(state, action) {
            state.job = action.payload
        },
        setOutputCategories(state, action) {
            state.outputCategories = action.payload
            updateSelectedOutputUrls(state)
        },
        setSelectedOutputUrls(state, action) {
            state.selectedOutputUrls = action.payload
        },
        setSelectedCategory(state, action) {
            state.selectedCategory = action.payload
            updateSelectedOutputUrls(state)
        },
        setSelectedCamera(state, action) {
            state.selectedCamera = action.payload
            updateSelectedOutputUrls(state)
        },
        setSelectedNode(state, action) {
            state.selectedNode = action.payload
            updateSelectedOutputUrls(state)
        },
        setFrameRange(state, action) {
            state.frameRange = action.payload
            updateSelectedOutputUrls(state)
        },
    },
})

function updateSelectedOutputUrls(state) {
    const {
        selectedCategory,
        selectedCamera,
        selectedNode,
        frameRange,
        outputCategories,
    } = state
    const categoryData = outputCategories[selectedCategory]
    if (!categoryData) {
        state.selectedOutputUrls = []
        return
    }
    let selected = []
    if (selectedCategory === 'cameras') {
        selected =
            selectedCamera == null // if null then all cameras
                ? categoryData.all
                : categoryData.cameras[selectedCamera]
    } else if (selectedCategory === 'nodes') {
        if (selectedNode == null) {
            // if null then all nodes
            selected = categoryData.all
        } else {
            const nodeData = categoryData.nodes[selectedNode]
            selected =
                selectedCamera == null // if null then all cameras
                    ? nodeData.all
                    : nodeData.cameras[selectedCamera]
        }
    } else {
        console.error('Invalid selectedCategory:', selectedCategory)
    }
    // Apply frame range filter
    const { start, end } = frameRange
    const validEnd = Math.min(end, selected.length - 1)
    const validStart = Math.min(start, validEnd)
    if (validStart === 0 && validEnd === 0) {
        state.selectedOutputUrls = selected // no frame range filter if both are 0 which is default
        return
    }
    state.selectedOutputUrls = selected.slice(validStart, validEnd + 1)
}

export const {
    setOutputJob,
    setOutputCategories,
    setSelectedOutputUrls,
    setSelectedCategory,
    setSelectedCamera,
    setSelectedNode,
    setFrameRange,
} = renderOutputsSlice.actions

export default renderOutputsSlice.reducer
