import Auth0ProviderWithHistory from 'auth0/Auth0ProviderWithHistory'
import StyledLoader from 'components/Loader'
import React, { memo, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import SwitchRoutes from './SwitchRoutes'

const AppRouter = () => {
    return (
        <BrowserRouter>
            <IntercomProvider
                autoBoot
                appId={process.env.REACT_APP_INTERCOM_APP_ID}
            >
                <Auth0ProviderWithHistory>
                    {/* <Suspense fallback={<div>Loading...</div>}> */}
                    <SwitchRoutes />
                    {/* </Suspense> */}
                </Auth0ProviderWithHistory>
            </IntercomProvider>
        </BrowserRouter>
    )
}
export default memo(AppRouter)
