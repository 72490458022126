export const items = [
    {
        title: 'Get started',
        items: [
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'I can’t reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'How do I change and reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
        ],
    },
    {
        title: 'Login & access',
        items: [
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'I can’t reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
        ],
    },
    {
        title: 'Billing & payments',
        items: [
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'I can’t reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'How do I change and reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
        ],
    },
    {
        title: 'My benefits',
        items: [
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'I can’t reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
        ],
    },
    {
        title: 'Account settings',
        items: [
            {
                title: 'How to upgrade to Pro account?',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'I forgot my password',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'I can’t reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
            {
                title: 'How to upgrade to Pro account?',
                content:
                    'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.',
            },
            {
                title: 'How do I change and reset my password',
                content:
                    "Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you're saving more than 99% on all products making it the sweetest deal for premium design assets around.",
            },
        ],
    },
]
