import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE

    const history = useHistory()

    const onRedirectCallback = () => {
        history.push('/')
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
            cacheLocation='localstorage'
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
