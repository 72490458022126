export const convertHexToRgba = (hex, opacity) => {
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const darkenColor = (hexColor, percentDarker) => {
    const red = parseInt(hexColor.slice(1, 3), 16)
    const green = parseInt(hexColor.slice(3, 5), 16)
    const blue = parseInt(hexColor.slice(5, 7), 16)

    const redDarker = Math.round(red - red * percentDarker)
    const greenDarker = Math.round(green - green * percentDarker)
    const blueDarker = Math.round(blue - blue * percentDarker)

    const hexRed = redDarker.toString(16).padStart(2, '0')
    const hexGreen = greenDarker.toString(16).padStart(2, '0')
    const hexBlue = blueDarker.toString(16).padStart(2, '0')
    const hexDarkerColor = `#${hexRed}${hexGreen}${hexBlue}`

    return hexDarkerColor
}
