import { createSlice } from '@reduxjs/toolkit'

import { market } from '../../mocks/market'

const marketSlice = createSlice({
    name: 'market',
    initialState: {
        market: market,
    },
})

export const selectMarket = (state) => state.market.market

export default marketSlice.reducer
