import { createSlice } from '@reduxjs/toolkit'

import { released } from '../../mocks/market'

const releasedSlice = createSlice({
    name: 'released',
    initialState: {
        released: released,
    },
})

export const selectReleased = (state) => state.released.released

export default releasedSlice.reducer
