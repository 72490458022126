import {
    applyMiddleware,
    combineReducers,
    configureStore,
} from '@reduxjs/toolkit'
import * as uppyReduxStore from '@uppy/store-redux'
import LogRocket from 'logrocket'
import { useDispatch, useSelector } from 'react-redux'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { jobApi } from 'store/api/uploadApi'
import API from '../services/api'
import { metaApi } from './api/metaApi'
import { usersApi } from './api/usersApi'
import authReducer from './slices/authSlice'
import commentsProductReducer from './slices/commentsProductSlice'
import commentsReducer from './slices/commentsSlice'
import creatorsReducer from './slices/creatorsSlice'
import customersReducer from './slices/customersSlice'
import displayReducer from './slices/displaySlice'
import faqReducer from './slices/faqSlice'
import followersReducer from './slices/followersSlice'
import jobReducer from './slices/jobSlice'
import marketReducer from './slices/marketSlice'
import modalReducer from './slices/modalSlice'
import notificationsReducer from './slices/notificationsSlice'
import paymentLinksReducer from './slices/paymentLinksSlice'
import postsReducer from './slices/postsSlice'
import productsReducer from './slices/productsSlice'
import purchaseHistoryReducer from './slices/purchaseHistorySlice'
import refundsReducer from './slices/refundsSlice'
import releasedReducer from './slices/releasedSlice'
import renderOutputsReducer from './slices/renderOutputsSlice'
import rendersReducer from './slices/rendersSlice'
import trafficReducer from './slices/trafficSlice'
import transactionsReducer from './slices/transactionsSlice'
import uploadRenderFileReducer from './slices/uploadRenderFileSlice'
import viewersReducer from './slices/viewersSlice'

const rootReducer = combineReducers({
    products: productsReducer,
    notifications: notificationsReducer,
    commentsProduct: commentsProductReducer,
    comments: commentsReducer,
    purchaseHistory: purchaseHistoryReducer,
    customers: customersReducer,
    creators: creatorsReducer,
    traffic: trafficReducer,
    viewers: viewersReducer,
    market: marketReducer,
    posts: postsReducer,
    refunds: refundsReducer,
    released: releasedReducer,
    followers: followersReducer,
    transactions: transactionsReducer,
    faq: faqReducer,
    paymentLinks: paymentLinksReducer,
    auth: authReducer,
    uploadRenderFile: uploadRenderFileReducer,
    modal: modalReducer,
    renders: rendersReducer,
    display: displayReducer,
    job: jobReducer,
    renderOutputs: renderOutputsReducer,
    [metaApi.reducerPath]: metaApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [jobApi.reducerPath]: jobApi.reducer,
    uppy: uppyReduxStore.reducer,
})

const enhancer = applyMiddleware(uppyReduxStore.middleware())

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'display'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const thunkExtraArgument = {}

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: { extraArgument: thunkExtraArgument },
            serializableCheck: {
                ignoredActions: [
                    PERSIST,
                    REHYDRATE,
                    PAUSE,
                    PURGE,
                    FLUSH,
                    REGISTER,
                    uppyReduxStore.STATE_UPDATE,
                ],
                ignoreState: true,
            },
            //TODO: the immutable check should ideally be on, but it's causing issues with the uppy store
            // The ignorePaths options need to be set to ignore the uppy store but doesnt seem to be available
            // on v1.x.x
            immutableCheck: false,
        }).concat([
            LogRocket.reduxMiddleware(),
            jobApi.middleware,
            usersApi.middleware,
            metaApi.middleware,
        ]),
    enhancers: [enhancer],
})

thunkExtraArgument.api = new API(store, {
    baseURL: process.env.REACT_APP_API_URL,
})

export const persistor = persistStore(store)
export const useAppDispatch = () => useDispatch()
