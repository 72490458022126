import { progress } from '../utils'

export const viewers = [
    {
        id: 0,
        product: 'Bento Matte 3D Illustration',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '30%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 854350,
                width: '70%',
            },
        ],
    },
    {
        id: 1,
        product: 'DSM - Geometry pattern',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '60%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 412350,
                width: '40%',
            },
        ],
    },
    {
        id: 2,
        product: 'Node - Crypto iOS UI design kit',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-3.jpg',
        image2x: '/images/content/product-pic-3@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '45%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 612350,
                width: '55%',
            },
        ],
    },
    {
        id: 3,
        product: 'TaskEz: Productivity App iOS UI Kit',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-4.jpg',
        image2x: '/images/content/product-pic-4@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '30%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 854350,
                width: '70%',
            },
        ],
    },
    {
        id: 4,
        product: 'Academe 3D Education Icons',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-5.jpg',
        image2x: '/images/content/product-pic-5@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '80%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 95350,
                width: '20%',
            },
        ],
    },
    {
        id: 5,
        product: 'DSM - Geometry pattern',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-6.jpg',
        image2x: '/images/content/product-pic-6@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '30%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 854350,
                width: '70%',
            },
        ],
    },
    {
        id: 6,
        product: 'Node - Crypto iOS UI design kit',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-1.jpg',
        image2x: '/images/content/product-pic-1@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '60%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 412350,
                width: '40%',
            },
        ],
    },
    {
        id: 7,
        product: 'TaskEz: Productivity App iOS UI Kit',
        date: '25 Sep - 4 Oct',
        image: '/images/content/product-pic-2.jpg',
        image2x: '/images/content/product-pic-2@2x.jpg',
        progress: progress(),
        items: [
            {
                title: 'Followers',
                color: '#B5E4CA',
                counter: 550000,
                width: '45%',
            },
            {
                title: 'Others',
                color: '#CABDFF',
                counter: 612350,
                width: '55%',
            },
        ],
    },
]
