export const followers = [
    {
        id: 0,
        man: 'Reed Parker',
        avatar: '/images/content/avatar-1.jpg',
        products: 16,
        followers: 3568,
        gallery: [
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
        ],
    },
    {
        id: 1,
        man: 'Tristian Hermiston',
        avatar: '/images/content/avatar-2.jpg',
        products: 32,
        followers: 2345,
        gallery: [
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
        ],
    },
    {
        id: 2,
        man: 'Rosetta Gottlieb',
        avatar: '/images/content/avatar-3.jpg',
        products: 8,
        followers: 980,
        message: true,
        gallery: [
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
        ],
    },
    {
        id: 3,
        man: 'Reed Parker',
        avatar: '/images/content/avatar-1.jpg',
        products: 16,
        followers: 3568,
        gallery: [
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
            {
                image: '/images/content/product-pic-2.jpg',
                image2x: '/images/content/product-pic-2@2x.jpg',
            },
            {
                image: '/images/content/product-pic-3.jpg',
                image2x: '/images/content/product-pic-3@2x.jpg',
            },
        ],
    },
    {
        id: 4,
        man: 'Tristian Hermiston',
        avatar: '/images/content/avatar-2.jpg',
        products: 32,
        followers: 2345,
        message: true,
        gallery: [
            {
                image: '/images/content/product-pic-4.jpg',
                image2x: '/images/content/product-pic-4@2x.jpg',
            },
            {
                image: '/images/content/product-pic-5.jpg',
                image2x: '/images/content/product-pic-5@2x.jpg',
            },
            {
                image: '/images/content/product-pic-1.jpg',
                image2x: '/images/content/product-pic-1@2x.jpg',
            },
        ],
    },
]
