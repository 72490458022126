import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import Slider from 'react-slick'
import { getFileNameFromUrl } from 'utils/url'
import Icon from '../Icon'
import styles from './ModalPreview.module.sass'
import Panel from './Panel'

const SlickArrow = ({
    currentSlide,
    slideCount,
    children,
    customHandleClick,
    ...props
}) => {
    return (
        <button {...props} onClick={customHandleClick}>
            {children}
        </button>
    )
}

const ModalPreview = ({ onClose, video, gallery = [], figcaption }) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [zoomed, setZoomed] = useState(false)
    const [loadedImages, setLoadedImages] = useState({}) // State to manage loaded images
    const [currentLoadedImageIndex, setCurrentLoadedImageIndex] = useState(0)
    const sliderRef = useRef()

    const toggleZoom = () => {
        setZoomed(!zoomed)
    }

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
            setIsFullScreen(true)
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
                setIsFullScreen(false)
            }
        }
    }

    const escFunction = useCallback(
        (e) => {
            if (e.keyCode === 27) {
                onClose()
                setCurrentSlide(0)
            }
        },
        [onClose],
    )

    const preloadImage = useCallback((index, gallery, loadedImages) => {
        if (!loadedImages[index] && gallery[index]) {
            setLoadedImages((prev) => ({
                ...prev,
                [index]: gallery[index],
            }))
        }
    }, [])

    // Loads the indicated image and its neighbors and set the current slide to the indicated index
    const setAndLoadImage = useCallback(
        (index) => {
            preloadImage(index, gallery, loadedImages)
            preloadImage(index - 1, gallery, loadedImages)
            preloadImage(index + 1, gallery, loadedImages)
            setCurrentSlide(index)
        },
        [gallery, loadedImages, preloadImage],
    )

    // Update the displayed image when the current slide changes
    useEffect(() => {
        if (sliderRef.current) {
            const loadedImageIndex = Object.keys(loadedImages).findIndex(
                (index) => parseInt(index) === currentSlide,
            )
            setCurrentLoadedImageIndex(loadedImageIndex)
        }
    }, [currentSlide, loadedImages])

    // Force the slider to go to the current slide when the loaded image changes
    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(currentLoadedImageIndex)
        }
    }, [currentLoadedImageIndex])

    const showNextLoadedImage = useCallback(() => {
        const nextIndex = (currentSlide + 1) % gallery.length
        if (nextIndex >= 0 && nextIndex < gallery.length) {
            setAndLoadImage(nextIndex)
        }
    }, [currentSlide, gallery, setAndLoadImage])

    const showPrevLoadedImage = useCallback(() => {
        const prevIndex = (currentSlide - 1 + gallery.length) % gallery.length
        if (prevIndex >= 0 && prevIndex < gallery.length) {
            setAndLoadImage(prevIndex)
        }
    }, [currentSlide, gallery, setAndLoadImage])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (sliderRef?.current) {
                if (event.key === 'ArrowRight') {
                    showNextLoadedImage()
                } else if (event.key === 'ArrowLeft') {
                    showPrevLoadedImage()
                }
            }
        }
        // Add keydown event listener for right and left arrow keys
        window.addEventListener('keydown', handleKeyDown)
        // remove the listener from document when the modal is closed
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [showNextLoadedImage, showPrevLoadedImage])

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement)
        }

        document.addEventListener('fullscreenchange', handleFullscreenChange)

        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange,
            )
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)
        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [escFunction])

    useEffect(() => {
        const target = document.querySelector('#modal-product')
        disableBodyScroll(target)
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    useEffect(() => {
        setAndLoadImage(currentSlide)
    }, [currentSlide, setAndLoadImage])

    const settings = {
        lazyLoad: false, // no need for lazy loading since we load images one by one anyway and disabling this makes transitions smooth
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: true,
        fade: true,
        draggable: true,
        nextArrow: (
            <SlickArrow customHandleClick={showNextLoadedImage}>
                <Icon name='arrow-right' size='24' />
            </SlickArrow>
        ),
        prevArrow: (
            <SlickArrow customHandleClick={showPrevLoadedImage}>
                <Icon name='arrow-left' size='24' />
            </SlickArrow>
        ),
    }

    const jumpToSlide = (index) => {
        // Validate index range
        if (index < 0 || index >= gallery.length) {
            console.log('Index out of range')
            return
        }
        setAndLoadImage(index)
    }

    return createPortal(
        <div id='modal-product' className={styles.modal}>
            <div className={styles.outer}>
                <OutsideClickHandler onOutsideClick={onClose}>
                    <Panel
                        jumpToSlide={jumpToSlide}
                        current={currentSlide + 1}
                        total={gallery.length}
                        onClose={() => {
                            onClose()
                        }}
                        galleryPanel={gallery}
                        isFullScreen={isFullScreen}
                        toggleFullScreen={toggleFullScreen}
                    />
                    {video && (
                        <div className={styles.video}>
                            <video controls>
                                <source
                                    src={video}
                                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                                />
                            </video>
                        </div>
                    )}
                    {Object.keys(loadedImages).length > 0 && (
                        <div
                            className={`${styles.wrapper} ${
                                zoomed ? styles.zoomed : ''
                            }`}
                        >
                            <Slider
                                className='photo-slider'
                                {...settings}
                                ref={(slider) => {
                                    sliderRef.current = slider
                                }}
                            >
                                {Object.keys(loadedImages).map((index) => (
                                    <div
                                        className={`${styles.slide}`}
                                        key={index}
                                        onClick={toggleZoom}
                                    >
                                        <div className={styles.preview}>
                                            <img
                                                src={loadedImages[index]}
                                                alt='Gallery'
                                            />
                                            <figcaption>
                                                {getFileNameFromUrl(
                                                    loadedImages[index],
                                                )}
                                            </figcaption>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            {figcaption && (
                                <div className={styles.figcaption}>
                                    {figcaption}
                                </div>
                            )}
                        </div>
                    )}
                </OutsideClickHandler>
            </div>
        </div>,
        document.body,
    )
}

export default ModalPreview
