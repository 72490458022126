import { createSlice } from '@reduxjs/toolkit'

import { commentsProduct } from '../../mocks/comments'

const commentsProductSlice = createSlice({
    name: 'commentsProduct',
    initialState: {
        commentsProduct: commentsProduct,
    },
})

export const selectCommentsProduct = (state) =>
    state.commentsProduct.commentsProduct

export default commentsProductSlice.reducer
