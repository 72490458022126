import cn from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon'
import styles from './Panel.module.sass'

const StyledInput = styled.input`
  padding: 5px;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  width: 50px;
  color: ${(props) => props.theme.colors.SLATE_GRAY};
  font-size: 15px;
  font-weight: ${(props) => props.theme.fontweight.BOLD};
  &:hover,
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.SLATE_GRAY};
  }
`

const StyledText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${(props) => props.theme.colors.SLATE_GRAY};
  font-size: 15px;
  font-weight: ${(props) => props.theme.fontweight.BOLD};
`

const Panel = ({
    onClose,
    galleryPanel,
    current,
    isFullScreen,
    toggleFullScreen,
    jumpToSlide,
}) => {
    const [inputValue, setInputValue] = useState(current)

    // Update the input value when the current slide changes
    useEffect(() => {
        setInputValue(current)
    }, [current])

    const debounceTimer = useRef()

    const handleInputChange = (event) => {
        const value = event.target.value
        if (Number.isNaN(Number(value))) {
            return
        }
        if (value === '') {
            setInputValue('')
            return
        }
        setInputValue(value)
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current)
        }
        debounceTimer.current = setTimeout(() => {
            let slideNumber = Number(value)
            // Clamp the slide number between 1 and the length of the gallery
            slideNumber = Math.max(
                1,
                Math.min(slideNumber, galleryPanel.length),
            )
            // Jump to the slide (subtract 1 because slider is 0-indexed)
            jumpToSlide(slideNumber - 1)
        }, 500)
    }

    return (
        <div
            className={cn(styles.panel, {
                [styles.panelGallery]: galleryPanel,
            })}
        >
            <div className={styles.actions}>
                {/* <span>{galleryPanel.length}</span> */}
            </div>
            <div className={styles.title}>
                <StyledText>
                    Frame
                    <StyledInput
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    <p>of</p> {galleryPanel.length}
                </StyledText>
            </div>
            <div className={styles.control}>
                <button className={styles.modalBtn}>
                    <Icon name='share' size='20' />
                </button>
                <a
                    href={galleryPanel[current - 1]} // We substract 1 because the 'current' is 1-based as we pass it as current={currentSlide + 1}
                    download
                    className={styles.modalBtn}
                >
                    <Icon name='download' size='24' />
                </a>
                <button className={styles.modalBtn} onClick={toggleFullScreen}>
                    <Icon
                        name={isFullScreen ? 'exitFullscreen' : 'expand'}
                        size='20'
                    />
                </button>
                <button className={styles.modalBtn} onClick={onClose}>
                    <Icon name='close' size='20' />
                </button>
            </div>
        </div>
    )
}

export default Panel
