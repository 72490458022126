import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getPaymentURL } from '../../utils'

export const getPaymentLinks = createAsyncThunk(
    'paymentLinks/getPaymentLinks',
    async (_, { extra: { api }, rejectWithValue }) => {
        try {
            const res = await api.get('/links?include=product')
            const mapIdToProduct = {}
            res.included.map((product) => {
                mapIdToProduct[product.id] = product
            })
            const paymentLinks = res.data.map(({ id, productId, active }) => ({
                paymentURL: getPaymentURL(id),
                active,
                productName: mapIdToProduct[productId].title,
            }))
            return paymentLinks
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

export const createPaymentLink = createAsyncThunk(
    'paymentLinks/createPaymentLink',
    async ({ productId }, { extra: { api }, rejectWithValue }) => {
        try {
            await api.post('/links', { productId })
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

export const getProductsWithNoLink = createAsyncThunk(
    'paymentLinks/getProductsWithNoLink',
    async (_, { extra: { api }, rejectWithValue }) => {
        try {
            const productsWithNoLink = await api.get(
                '/products?haveNoLink=true',
            )
            return productsWithNoLink.data
        } catch (err) {
            return rejectWithValue(err.message)
        }
    },
)

const paymentLinksSlice = createSlice({
    name: 'paymentLinks',
    initialState: {
        paymentLinks: [],
        isLoaded: false,
        productsWithNoLink: [],
        isProductsWithNoLinkLoaded: false,
    },
    reducers: {
        clearProductsWithNoLink: (state) => {
            state.productsWithNoLink = []
            state.isProductsWithNoLinkLoaded = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentLinks.fulfilled, (state, action) => {
                state.isLoaded = true
                state.paymentLinks = action.payload
            })
            .addCase(createPaymentLink.fulfilled, (state) => {
                state.isLoaded = false
                state.paymentLinks = []
            })
            .addCase(getProductsWithNoLink.fulfilled, (state, action) => {
                state.isProductsWithNoLinkLoaded = true
                state.productsWithNoLink = action.payload
            })
    },
})

export const { clearProductsWithNoLink } = paymentLinksSlice.actions

export const selectPaymentLinks = (state) => state.paymentLinks.paymentLinks

export default paymentLinksSlice.reducer
