import { useAuth0 } from '@auth0/auth0-react'
import cn from 'classnames'
import LogRocket from 'logrocket'
import React, { useState, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useGetIntercomHashQuery } from 'store/api/usersApi'
import analytics, { EVENTS } from 'utils/analytics'
import { clearAuthState } from '../../../store/slices/authSlice'
import Icon from '../../Icon'
import styles from './User.module.sass'

const CUSTOMER_BILLING_URL = `${process.env.REACT_APP_API_URL}/api/users/me/billing-portal-session`

const User = ({ className }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const { logout, user, isAuthenticated } = useAuth0()
    const { authToken } = useSelector((state) => state.auth)
    const [shouldFetch, setShouldFetch] = useState(false)
    const { data: intercomHash } = useGetIntercomHashQuery(
        {},
        {
            skip: !shouldFetch,
        },
    )
    const { update } = useIntercom()

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldFetch(true)
        }, 2000)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (isAuthenticated && user) {
            LogRocket.identify(user.sub, {
                email: user.email,
                emailVerified: user.email_verified,
                name: user.nickname,
                avatarURL: user.picture,
                profile: user.profile,
            })
            analytics.identify(user.sub, {
                email: user.email,
                name: user.nickname,
            })
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated && user) {
            if (intercomHash?.hash) {
                try {
                    update({
                        email: user.email,
                        name: user.email,
                        userHash: intercomHash.hash,
                        customAttributes: {
                            Auth0Id: user.sub,
                        },
                    })
                } catch (e) {
                    console.log('Intercom error', e)
                }
            }
        }
    }, [isAuthenticated, user, intercomHash, update])

    const handleLogout = () => {
        analytics.track(EVENTS.LOGOUT)
        dispatch(clearAuthState())
        logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL })
    }

    const handleOnBillingClick = async () => {
        analytics.track(EVENTS.VISIT_BILLING_PAGE)
        const response = await fetch(CUSTOMER_BILLING_URL, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
        if (response.ok) {
            const { sessionUrl } = await response.json()
            window.location.href = sessionUrl
        }
    }

    /*
  const items = [
    {
      menu: [
        {
          title: "Profile",
          url: "/shop",
        },
        {
          title: "Edit profile",
          url: "/settings",
        },
    {
      menu: [
        {
          title: "Analytics",
          icon: "bar-chart",
          url: "/customers/overview",
        },
        {
          title: "Affiliate center",
          icon: "ticket",
          url: "/affiliate-center",
        },
        {
          title: "Explore creators",
          icon: "grid",
          url: "/explore-creators",
        },
      ],
    },
    {
      menu: [
        {
          title: "Upgrade Plan",
          icon: "leaderboard",
          color: true,
          url: "/upgrade-to-pro",
        },
      ],
    },
    {
      menu: [
        {
          title: "Account Settings",
          url: "/settings",
        },
        {
          title: "Log out",
          onClick: handleLogout,
        },
      ],
    },
  ];
  */

    const items = [
        {
            menu: [
                {
                    title: 'Upgrade Plan',
                    icon: 'leaderboard',
                    color: true,
                    url: '/upgrade-to-pro',
                },
                {
                    title: 'Profile & Settings',
                    icon: 'setting',
                    url: '/settings',
                },
                {
                    title: 'Manage Billing',
                    onClick: handleOnBillingClick,
                    icon: 'payment',
                },
                {
                    title: 'Credits',
                    icon: 'diamond',
                    url: '/credits',
                },
            ],
        },
        {
            menu: [
                {
                    title: isAuthenticated ? 'Log out' : 'Log in',
                    onClick: isAuthenticated
                        ? handleLogout
                        : () => history.push('/login'),
                },
            ],
        },
    ]

    const renderLink = (x, index) => {
        if (!x.url) {
            return (
                <button className={styles.item} onClick={x.onClick} key={index}>
                    {x.icon && <Icon name={x.icon} size='24' />}
                    {x.title}
                </button>
            )
        }
        if (x.url.startsWith('http:') || x.url.startsWith('https:')) {
            return (
                <a
                    className={cn(styles.item, { [styles.color]: x.color })}
                    href={x.url}
                    key={index}
                >
                    {x.icon && <Icon name={x.icon} size='24' />}
                    {x.title}
                </a>
            )
        }
        return (
            <NavLink
                className={cn(styles.item, { [styles.color]: x.color })}
                activeClassName={styles.active}
                to={x.url}
                onClick={() => setVisible(false)}
                key={index}
            >
                {x.icon && <Icon name={x.icon} size='24' />}
                {x.title}
            </NavLink>
        )
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
                className={cn(styles.user, className, {
                    [styles.active]: visible,
                })}
            >
                <button
                    className={styles.head}
                    onClick={() => setVisible(!visible)}
                >
                    <img
                        src={
                            user ? user?.picture : '/images/content/avatar.jpg'
                        }
                        alt='Avatar'
                    />
                </button>
                <div className={styles.body}>
                    {items.map((item, index) => (
                        <div className={styles.menu} key={index}>
                            {item.menu.map(renderLink)}
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default User
